import React, { useEffect, useState, Component } from "react";
import "./sitemap.css";
import NavBar from "../components/navbar";
import Footer3CS from "../components/homepage-copyright";
import Arrow from "../template/arrow";
import { Helmet } from "react-helmet";
//import vote from "../images/bestweb-24-vote4us.webp"; // BestWeb - logo
import TopWeb from "../images/TopWeb-May-2024.jpg"; //  TopWeb - logo

export default function Sitemap() {
  //  BestWeb - start
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsMobile(window.innerWidth <= 600);

      const handleResize = () => {
        setIsMobile(window.innerWidth <= 600);
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  //  BestWeb - end

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  class BulletPoint extends Component {
    render() {
      return (
        <li>
          <a href={this.props.link} className="para2-sitemap">
            {this.props.text}
          </a>
        </li>
      );
    }
  }

  return (
    <div>
      <div className="sitemap">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Diversified Conglomerates in Sri Lanka | Lalan Group</title>
          <meta
            name="title"
            content="Diversified Conglomerates in Sri Lanka | Lalan Group"
          />
          <meta
            name="description"
            content="Founded in the year 1940, Lalan group owns over 46 subsidiaries making it among the largest diversified conglomerates in Sri Lanka. "
          />
          <meta
            name="keywords"
            content="Diversified Conglomerates in Sri Lanka, Lalan Group"
          />
          <link rel="canonical" href="https://www.lalangroup.com/sitemap/" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
          <meta
            property="og:title"
            content="Sitemap - Diversified Conglomerates in Sri Lanka | Lalan Group"
          />
          <meta
            property="og:url"
            content="https://www.lalangroup.com/sitemap/"
          />
          <meta
            property="og:site_name"
            content="Diversified Conglomerates in Sri Lanka | Lalan Group"
          />
        </Helmet>
        <NavBar />
        <center>
          <div className="sitemap-body">
            <div>
              <br />
              <div className="sitemap-text">
                <h1 className="topic-sitemap">SITEMAP</h1> <br />
                <br />
                <table style={{ width: "100%", textAlign: "start" }}>
                  <tr>
                    <td style={{ width: "170px", verticalAlign: "top" }}>
                      <p className="para-sitemap">
                        Home - Lalan&nbsp;Group
                        <br />
                        <br />
                      </p>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <a
                        className="para2-sitemap"
                        href="/"
                        style={{ marginTop: "9px" }}
                      >
                        Home - Lalan Group
                      </a>
                      <br />
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "170px", verticalAlign: "top" }}>
                      <p className="para-sitemap">
                        Sectors
                        <br />
                        <br />
                      </p>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <ul className="ul-bulletPoint">
                        <BulletPoint
                          link="/sectors/plantations/"
                          text="Plantations"
                        />
                        <BulletPoint link="/sectors/gloves/" text="Gloves" />
                        <BulletPoint
                          link="/sectors/rubber-products/"
                          text="Rubber Products"
                        />
                        <BulletPoint
                          link="/sectors/packaging/"
                          text="Packaging"
                        />
                        <BulletPoint
                          link="/sectors/renewable-energy/"
                          text="Renewable Energy"
                        />
                        <BulletPoint link="/sectors/leisure/" text="Leisure" />
                        <BulletPoint
                          link="/sectors/contract-manufacturing/"
                          text="Contract Manufacturing"
                        />
                        <BulletPoint
                          link="/sectors/thread&fabric/"
                          text="Thread & Fabric"
                        />
                        <br />
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "170px", verticalAlign: "top" }}>
                      <p className="para-sitemap">
                        <a className="para-sitemap-a" href="/about/">
                          About Us
                        </a>
                        <br />
                        <br />
                      </p>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <ul className="ul-bulletPoint">
                        <BulletPoint
                          link="/aboutus/timeline/"
                          text="Timeline"
                        />
                        <BulletPoint
                          link="/aboutus/gobal-presence/"
                          text="Global Presence"
                        />{" "}
                        <br />
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "170px", verticalAlign: "top" }}>
                      <p className="para-sitemap">
                        Sustainability
                        <br />
                        <br />
                      </p>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <ul className="ul-bulletPoint">
                        <BulletPoint link="/sustainability/leaf/" text="LEAF" />
                        <BulletPoint
                          link="/sustainability/lalith-hapangama-foundation/"
                          text="Lalith Hapangama Foundation"
                        />{" "}
                        <br />
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "170px", verticalAlign: "top" }}>
                      <p className="para-sitemap">
                        Contact Us
                        <br />
                        <br />
                      </p>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <a
                        className="para2-sitemap"
                        href="/contact-us/"
                        style={{ marginTop: "9px" }}
                      >
                        Contact Us
                      </a>
                      <br />
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "170px", verticalAlign: "top" }}>
                      <p className="para-sitemap">
                        Privacy Policy
                        <br />
                        <br />
                      </p>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <a
                        className="para2-sitemap"
                        href="/privacy-policy/"
                        style={{ marginTop: "9px" }}
                      >
                        Privacy Policy
                      </a>
                      <br />
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "170px", verticalAlign: "top" }}>
                      <p className="para-sitemap">
                        Cookie Policy
                        <br />
                        <br />
                      </p>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <a
                        className="para2-sitemap"
                        href="/cookie-policy/"
                        style={{ marginTop: "9px" }}
                      >
                        Cookie Policy
                      </a>
                      <br />
                      <br />
                      <br />
                      <br />
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </center>

        <div className="b-t">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginLeft: isMobile ? "0px" : "40px",
              paddingBottom: isMobile ? "30px" : "0px",
              alignItems: "start",
            }}
          >
            {/* BestWeb - start */}
            {/* <div>
              <a
                href="https://www.vote.bestweb.lk/site/www_lalangroup_lk"
                target="_blank"
              >
                <img className="logopic" src={vote} alt="logo" />
              </a>
            </div> */}
            {/* BestWeb - end */}

            {/* TopWeb - start */}
            <div>
              <a href="https://topweb.lk/may2024/lalangroup" target="_blank">
                <img
                  style={{ marginLeft: "20px" }}
                  className="logopic-2"
                  src={TopWeb}
                  alt="logo"
                />
              </a>
            </div>
            {/* TopWeb - end */}
          </div>
        </div>

        <div className="sitemap-footer">
          <br />
          <div>
            <Footer3CS />
          </div>
        </div>
        <Arrow />
      </div>
    </div>
  );
}
